import React, { memo } from 'react'
import PropTypes from 'prop-types'

export const TableCell = props => {
  return <td className={props.className}>{props.children}</td>
}

TableCell.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
}

export default memo(TableCell)
